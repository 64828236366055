<script setup lang="ts">
import { QIcon, QItem, QItemSection } from 'quasar';
import { inject } from 'vue';

import { wsMenuInjectionKey } from './ws-menu-injection-key';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  href?: string;
  target?: string;
  icon?: string;
  disabled?: boolean;
  inset?: boolean;
  endIcon?: string;
  aid: string;
}>();

const slots = defineSlots<{
  default(): unknown;
  icon?(): unknown;
  endIcon?(): unknown;
}>();

const emit = defineEmits<{
  click: [event: PointerEvent];
}>();

const context = inject(wsMenuInjectionKey);

if (!context) {
  throw new Error('WsMenuItem must be used within a WsMenu');
}

const { minWidth } = context;
</script>

<template>
  <QItem
    clickable
    :href="props.href"
    :target="props.target"
    :role="props.href ? undefined : 'button'"
    :disable="props.disabled"
    class="ws-menu-item"
    :class="{ 'ws-menu-item--inset': props.inset }"
    :style="{ minWidth }"
    :aid="aid"
    @click="emit('click', $event as PointerEvent)"
  >
    <QItemSection
      v-if="props.icon || slots.icon"
      avatar
      class="ws-menu-item__icon-section"
    >
      <slot name="icon">
        <QIcon :name="props.icon" size="14px" />
      </slot>
    </QItemSection>

    <QItemSection no-wrap>
      <slot />
    </QItemSection>

    <QItemSection v-if="props.endIcon || slots.endIcon" side>
      <slot name="endIcon">
        <QIcon :name="props.endIcon" size="12px" />
      </slot>
    </QItemSection>
  </QItem>
</template>

<style scoped lang="scss">
.ws-menu-item {
  min-height: 40px;
  padding-inline-start: 22px;
  padding-inline-end: 20px;

  &--inset {
    padding-inline-start: 55.5px;
  }

  &__icon-section {
    min-width: auto;
    padding-inline-end: 12px;
  }
}
</style>
