<script setup lang="ts">
import { useTemplateRef } from 'vue';
import tinycolor from 'tinycolor2';

const saturation = defineModel<number>('saturation', {
  required: true,
  validator: (value: number) => value >= 0 && value <= 1,
});
const brightness = defineModel<number>('brightness', {
  required: true,
  validator: (value: number) => value >= 0 && value <= 1,
});

defineProps<{
  hue: number;
}>();

const container = useTemplateRef('container');

function handleSaturationBrightnessChange(event: MouseEvent | TouchEvent) {
  const { clientX, clientY } = 'touches' in event ? event.touches[0] : event;

  if (!container.value) return;

  const rect = container.value.getBoundingClientRect();

  const x = clientX - rect.left;
  const y = clientY - rect.top;

  saturation.value = Math.max(0, Math.min(x / rect.width, 1));
  brightness.value = Math.max(0, Math.min(1 - y / rect.height, 1));
}

function handlePointerDown(event: MouseEvent | TouchEvent) {
  handleSaturationBrightnessChange(event);

  document.addEventListener('pointermove', handleSaturationBrightnessChange);
  document.addEventListener(
    'pointerup',
    () =>
      document.removeEventListener(
        'pointermove',
        handleSaturationBrightnessChange
      ),
    { once: true }
  );
}
</script>

<template>
  <div
    ref="container"
    class="relative cursor-crosshair rounded"
    :style="{ background: `hsl(${hue}deg 100% 50%)` }"
    @pointerdown="handlePointerDown"
  >
    <div class="absolute inset-0 rounded bg-gradient-to-r from-white" />
    <div
      class="absolute inset-0 rounded bg-gradient-to-b from-transparent to-black"
    />

    <div
      class="pointer-events-none absolute size-4 -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-white drop-shadow-sm"
      :style="{
        insetInlineStart: `${saturation * 100}%`,
        insetBlockStart: `${(1 - brightness) * 100}%`,
        backgroundColor: tinycolor({
          h: hue,
          s: saturation,
          v: brightness,
        }).toHslString(),
      }"
    />
  </div>
</template>
