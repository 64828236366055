<script setup lang="ts">
withDefaults(
  defineProps<{
    tag?: string;
  }>(),
  {
    tag: 'div',
  }
);

const slots = defineSlots<{
  header?(): unknown;
  default(): unknown;
  footer?(): unknown;
}>();
</script>

<template>
  <!-- With header/footer -->
  <Component
    :is="tag"
    v-if="slots.header ?? slots.footer"
    class="rounded-lg bg-white shadow"
  >
    <div v-if="slots.header" class="border-b border-gray-100 p-[14px]">
      <slot name="header" />
    </div>

    <div class="p-[14px]">
      <slot />
    </div>

    <div v-if="slots.footer" class="border-t border-gray-100 p-[14px]">
      <slot name="footer" />
    </div>
  </Component>

  <!-- Without header and footer -->
  <Component :is="tag" v-else class="rounded-lg bg-white p-[24px] shadow">
    <slot />
  </Component>
</template>
