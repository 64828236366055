<script setup lang="ts">
import tinycolor from 'tinycolor2';
import { reactive, ref } from 'vue';

import WsCard from '../ws-card.vue';
import { WsButton } from '../../button';
import { WsInput } from '../../input';

import WsColorPickerHueSlider from './ws-color-picker-hue-slider.vue';
import WsColorPickerSaturationBrightnessPicker from './ws-color-picker-saturation-brightness-picker.vue';

const props = defineProps<{
  initialColor?: string;
}>();

const emit = defineEmits<{
  done: [color: string];
  cancel: [];
}>();

const hsv = reactive({
  h: 0, // 0-360
  s: 0.5, // 0-1
  v: 0.5, // 0-1
});

const color = ref(tinycolor());
const textInput = ref('');

function setColorFromHsv(hsv: { h: number; s: number; v: number }) {
  color.value = tinycolor(hsv);
  textInput.value = color.value.toHexString().toUpperCase();
}

function setColorFromString(str: string) {
  const newColor = tinycolor(str);

  if (!newColor.isValid()) {
    return;
  }

  color.value = newColor;

  const { h, s, v } = newColor.toHsv();
  hsv.h = h;
  hsv.s = s;
  hsv.v = v;
}

const primaryColorRgb = window
  .getComputedStyle(document.body)
  .getPropertyValue('--color-primary');

setColorFromString(props.initialColor ?? `rgb ${primaryColorRgb}`);
textInput.value = color.value.toHexString().toUpperCase();
</script>

<template>
  <WsCard
    tag="form"
    class="flex flex-col"
    @submit.prevent="emit('done', color.toHexString())"
  >
    <WsColorPickerSaturationBrightnessPicker
      v-model:saturation="hsv.s"
      v-model:brightness="hsv.v"
      :hue="hsv.h"
      class="h-[110px]"
      @update:saturation="setColorFromHsv({ ...color.toHsv(), s: $event })"
      @update:brightness="setColorFromHsv({ ...color.toHsv(), v: $event })"
    />

    <WsColorPickerHueSlider
      v-model="hsv.h"
      class="mt-2.5"
      @update:model-value="setColorFromHsv({ ...color.toHsv(), h: $event })"
    />

    <div class="mt-2.5 flex flex-nowrap items-center gap-2">
      <WsInput
        v-model="textInput"
        size="xs"
        hide-bottom-space
        class="flex-1"
        aid="COLOR_PICKER_CUSTOM_COLOR_TEXT_INPUT"
        @update:model-value="setColorFromString"
      />

      <div
        class="h-8 w-8 rounded border border-black/10"
        :style="{ background: color.toHslString() }"
      />
    </div>

    <template #footer>
      <div class="flex justify-end gap-2.5">
        <WsButton
          type="button"
          variant="outlined"
          color="gray-500"
          size="sm"
          aid="COLOR_PICKER_CUSTOM_COLOR_CANCEL"
          @click="emit('cancel')"
        >
          Cancel
        </WsButton>

        <WsButton type="submit" size="sm" aid="COLOR_PICKER_CUSTOM_COLOR_DONE">
          Done
        </WsButton>
      </div>
    </template>
  </WsCard>
</template>
