<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue';

defineOptions({
  inheritAttrs: false,
});

withDefaults(
  defineProps<{
    content: string;
    scale?: number | 'fit';
  }>(),
  {
    scale: 1,
  }
);

const contentElement = ref<HTMLDivElement | null>(null);
const adaptiveScale = ref(1);

const observer = new ResizeObserver(([entry]) => {
  adaptiveScale.value = Math.min(
    entry.target.clientWidth / entry.target.scrollWidth,
    entry.target.clientHeight / entry.target.scrollHeight
  );
});

onMounted(() => {
  if (!contentElement.value) {
    return;
  }

  observer.observe(contentElement.value);
});

onUnmounted(() => {
  observer.disconnect();
});
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    ref="contentElement"
    class="content-element"
    :style="{ '--html-viewer-scale': scale === 'fit' ? adaptiveScale : scale }"
    v-html="content"
  />
</template>

<style lang="scss" scoped>
.content-element {
  max-width: 100%;
  max-height: 100%;
  transform: scale(clamp(0.1, var(--html-viewer-scale), 1));
  transform-origin: top left;
  transition: all 0.1s ease-in-out;

  :deep(img) {
    display: unset;
    max-width: unset;
  }
}
</style>
