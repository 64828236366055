<script setup lang="ts">
/**
 * The dialog wrapper\
 * Should never be imported directly
 *
 * Implements the basic dialog structure\
 * Content component is dynamically created (passed in the options)\
 * See [quasar dialog plugin](https://quasar.dev/quasar-plugins/dialog#invoking-custom-component)
 */
import { ref } from 'vue';
import { useDialogPluginComponent, QDialog, QCard, QSeparator } from 'quasar';

import type { DialogOptions, IDialog } from './dialog.types';
import WsDialogSection from './ws-dialog-section.vue';
import WsIconButton from '../button/ws-icon-button.vue';

defineOptions({
  inheritAttrs: false,
});

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const props = defineProps<{ options: DialogOptions }>();

const persistent = ref(props.options.persistent ? true : false);

const dialogApi: IDialog = {
  input: props.options.input,
  setPersistent(persist: boolean) {
    persistent.value = persist;
  },
  close(output?: unknown): void {
    onDialogOK(output);
  },
};

defineEmits([...useDialogPluginComponent.emits]);
</script>

<template>
  <QDialog
    ref="dialogRef"
    :persistent="persistent"
    no-shake
    class="ws-dialog"
    transition-show="fade"
    transition-hide="fade"
    transition-duration="200"
    backdrop-filter="brightness(50%)"
    @hide="onDialogHide"
  >
    <QCard class="q-dialog-plugin ws-dialog__card" :aid="props.options.aid">
      <WsDialogSection class="row no-wrap ws-dialog__header items-center">
        <div class="col ws-dialog__header__text">
          {{ props.options.headerText }}
        </div>

        <WsIconButton
          v-if="!persistent"
          :aid="props.options.aid + '_DISMISS'"
          color="gray-400"
          variant="text"
          @click="onDialogCancel()"
        >
          <span class="fa-light fa-xmark" />
        </WsIconButton>
      </WsDialogSection>

      <QSeparator
        v-if="props.options.showTopSeparator !== false"
        color="grey-100"
      />

      <!-- DIALOG CONTENT -->
      <component :is="props.options.component" :dialog="dialogApi" />
    </QCard>
  </QDialog>
</template>

<style scoped lang="scss">
.ws-dialog {
  &__card {
    width: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  &__header {
    padding-block: 0;
    padding-inline: 24px 12px;

    &__text {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      word-wrap: break-word;
      padding-block: 14px;
    }
  }
}
</style>
