<script setup lang="ts">
import { QDate, QTime, date } from 'quasar';
import { computed, onMounted, ref } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    id?: string;
    aid: string;
    type?: 'date' | 'time' | 'datetime';
    dateMask?: string;
    timeMask?: string;
    minYearMonth?: string;
  }>(),
  {
    type: 'datetime',
    dateMask: 'MM/DD/YY',
    timeMask: 'hh:mm A',
  }
);

const model = defineModel<string>();

const DATETIME_PATTERN =
  /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4}) (0[1-9]|1[0-2]):([0-5][0-9]) (AM|PM)$/;
const DATE_PATTERN =
  /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4})$/;
const TIME_PATTERN = /^(0[1-9]|1[0-2]):([0-5][0-9]) (AM|PM)$/;

const defaultDate = computed((): string =>
  date.formatDate(new Date(), props.dateMask)
);
const defaultTime = computed((): string =>
  date.formatDate(new Date(), props.timeMask)
);

function validDate(dateStr: string, regex: RegExp): boolean {
  return date.isValid(dateStr) && regex?.test(dateStr);
}

function validTime(timeStr: string): boolean {
  return TIME_PATTERN.test(timeStr);
}

const formattedDate = computed((): string => {
  const parts = model.value?.split(' ');

  const parsedDate = new Date(parts?.[0] ?? '');
  return date.formatDate(parsedDate, props.dateMask);
});

const formattedTime = computed((): string => {
  const parts = model.value?.split(' ');
  let partIndex = 1;
  if (props?.type === 'time') {
    partIndex = 0;
  }
  return `${parts?.[partIndex]} ${parts?.[partIndex + 1]}`;
});

const datePart = ref(formattedDate.value);
const timePart = ref(formattedTime.value);

function determineValidDate(): string {
  if (
    !validDate(formattedDate.value, DATE_PATTERN) &&
    !validTime(formattedTime.value)
  ) {
    datePart.value = defaultDate.value;
    timePart.value = defaultTime.value;
  } else if (validDate(formattedDate.value, DATE_PATTERN)) {
    timePart.value = defaultTime.value;
  } else {
    datePart.value = defaultDate.value;
  }

  return `${datePart.value} ${timePart.value}`;
}

function validateDateTimeFields(): void {
  if (!validDate(model.value ?? '', DATETIME_PATTERN)) {
    model.value = determineValidDate();
  }
}

onMounted(() => {
  validateDateTimeFields();
});

const updateModel = () => {
  switch (props.type) {
    case 'datetime':
      model.value = `${datePart.value} ${timePart.value}`;
      break;
    case 'date':
      model.value = datePart.value;
      break;
    case 'time':
      model.value = timePart.value;
  }
};
</script>

<template>
  <div class="ws-date-picker">
    <QDate
      v-if="type === 'datetime' || type === 'date'"
      v-model="datePart"
      :bordered="false"
      :mask="dateMask"
      class="ws-date-picker-date"
      :aid="`${aid}_DATE_PICKER`"
      :navigation-min-year-month="minYearMonth"
      @update:model-value="updateModel"
    />
    <QTime
      v-if="type === 'datetime' || type === 'time'"
      v-model="timePart"
      :bordered="false"
      :mask="timeMask"
      class="ws-date-picker-time"
      :aid="`${aid}_TIME_PICKER`"
      @update:model-value="updateModel"
    />
  </div>
</template>

<style scoped lang="scss">
.ws-date-picker {
  display: flex;
  gap: 10px;
  padding: 20px;

  &-date,
  &-time {
    color: var(color-gray-500);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    :deep(.q-time__main) {
      padding: 20px;
    }

    :deep(.q-date__header),
    :deep(.q-time__header) {
      height: 60px;
    }

    :deep(.q-date__header) {
      padding: 4px 22px;
    }

    :deep(.q-time__header) {
      min-height: 0;
    }

    :deep(.q-time__header-ampm) {
      font-size: 12px;
      font-weight: 600;
    }

    :deep(.q-date__calendar-item .q-btn) {
      font-size: 12px; // TODO: change size to be rem or var
    }

    :deep(.q-date__navigation .q-btn) {
      font-size: 12px; // TODO: change size to be rem or var
    }

    :deep(.q-time__container-child) {
      background: rgb(var(--color-gray-100));
    }

    :deep(.q-date__header-subtitle) {
      font-weight: 700;
      font-size: 12px;
      opacity: 1;
    }

    :deep(.q-date__header-title-label),
    :deep(.q-time__header-label) {
      font-weight: 700;
      font-size: 16px; // TODO: change size to be rem or var
    }

    :deep(.q-date__header),
    :deep(.q-time__header) {
      background-color: white;
      color: var(--color-gray-500);
      border-bottom: 1px solid rgb(var(--color-gray-100));
    }

    :deep(.q-date__months-item .q-btn),
    :deep(.q-date__years-item .q-btn) {
      padding: 4px 10px;
      border-radius: 4px;
    }
  }
}
</style>
