<script setup lang="ts" generic="TValue extends OptionValue">
import { Listbox, ListboxLabel } from '@headlessui/vue';
import { computed, provide, ref, toRef } from 'vue';

import { OptionValue, wsSelectInjectionKey } from './ws-select-injection-key';
import WsSelectOptions from './ws-select-options.vue';
import WsSelectPanel from './ws-select-panel.vue';
import WsSelectTrigger from './ws-select-trigger.vue';

defineOptions({
  inheritAttrs: false,
});

const modelValue = defineModel<TValue | TValue[] | null>({ default: null });

const props = withDefaults(
  defineProps<{
    /**
     * Function to get the label of an option.
     * Used to display the label of each option and each chip.
     */
    optionLabel: (value: TValue) => string;
    /**
     * Function to get the key of an option.
     * Used for rendering chips and for automation ID.
     */
    optionKey: (value: TValue) => PropertyKey;
    /** Disables the select. */
    disabled?: boolean;
    /**
     * Label to display above (when `label-position="outside"`) or inside (when
     * `label-position="inside"`) the trigger element.
     */
    label?: string;
    /** Where to display the label. */
    labelPosition?: 'inside' | 'outside';
    /** Whether to allow selecting multiple options. */
    multiple?: boolean;
    /**
     * Whether to show checkboxes (on the left) instead of checkmarks (on the
     * right) for multiple select.
     */
    checkboxes?: boolean;
    chips?: boolean;
    /**
     * Effectively disables the select, but has a different visual appearance.
     */
    readonly?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    variant?: 'outlined' | 'filter' | 'text';
    underline?: boolean;
    /** Puts the select in a loading state. */
    loading?: boolean;
    /** Error message to display below the select. */
    error?: string;
    /** Automation ID. */
    aid: string;
    /**
     * Skips rendering the default trigger and panel, allowing for custom
     * content.
     */
    advanced?: boolean;
  }>(),
  {
    labelPosition: 'inside',
    size: 'md',
    variant: 'outlined',
  }
);

provide(wsSelectInjectionKey, {
  modelValue,
  optionLabel: toRef(() => props.optionLabel as (value: OptionValue) => string),
  optionKey: toRef(
    () => props.optionKey as (value: OptionValue) => PropertyKey
  ),
  label: toRef(() => props.label),
  labelPosition: toRef(() => props.labelPosition),
  variant: toRef(() => props.variant),
  underline: toRef(() => props.underline),
  size: toRef(() => props.size),
  multiple: toRef(() => props.multiple),
  checkboxes: toRef(() => props.checkboxes),
  chips: toRef(() => props.chips),
  disabled: toRef(() => props.disabled),
  readonly: toRef(() => props.readonly),
  loading: toRef(() => props.loading),
  searchInput: ref<HTMLInputElement | null>(null),
  hasError: computed(() => !!props.error),
  aid: toRef(() => props.aid),
  triggerElement: ref<HTMLElement | null>(null),

  clear() {
    modelValue.value = props.multiple ? [] : null;
  },

  removeSelection(value) {
    if (!props.multiple || !Array.isArray(modelValue.value)) {
      throw new Error('Cannot remove selection from non-multiple select');
    }

    modelValue.value = modelValue.value.filter((v) => v !== value);
  },
});
</script>

<template>
  <Listbox
    v-model="modelValue"
    :multiple="multiple"
    :disabled="disabled || readonly"
    as="div"
    :class="[
      {
        'inline-block': variant === 'filter' || variant === 'text',
        'text-xs': size === 'xs' || size === 'sm',
        'text-sm': size === 'md',
        'text-base': size === 'lg',
      },
      $attrs.class,
    ]"
  >
    <ListboxLabel
      v-if="label && labelPosition === 'outside'"
      class="mb-1.5 inline-block"
    >
      {{ label }}
    </ListboxLabel>

    <slot v-if="advanced" />

    <template v-else>
      <WsSelectTrigger />

      <WsSelectPanel>
        <WsSelectOptions>
          <slot />
        </WsSelectOptions>
      </WsSelectPanel>
    </template>

    <span
      v-if="error"
      class="block text-danger"
      :class="{
        'mt-0.5': size === 'xs' || size === 'sm',
        'mt-1': size === 'md' || size === 'lg',
        'text-xs': size === 'xs' || size === 'sm' || size === 'md',
        'text-sm': size === 'lg',
      }"
      aria-live="polite"
    >
      {{ error }}
    </span>
  </Listbox>
</template>
