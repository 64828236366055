<script setup lang="ts">
import { QBtn, QIcon } from 'quasar';

defineOptions({
  inheritAttrs: false,
});

withDefaults(
  defineProps<{
    color?:
      | 'primary'
      | 'promotion'
      | 'danger'
      | 'gray-400'
      | 'gray-500'
      | 'white';
    disabled?: boolean;
    href?: string; // eslint-disable-line vue/require-default-prop
    icon?: string; // eslint-disable-line vue/require-default-prop
    iconEnd?: boolean;
    label?: string; // eslint-disable-line vue/require-default-prop
    loading?: boolean;
    size?: 'sm' | 'md' | 'lg';
    target?: string; // eslint-disable-line vue/require-default-prop
    type?: 'submit' | 'reset' | 'button'; // eslint-disable-line vue/require-default-prop
    variant?: 'contained' | 'outlined' | 'flat' | 'text';
    aid: string;
  }>(),
  {
    color: 'primary',
    size: 'md',
    variant: 'contained',
  }
);

defineSlots<{
  default: unknown;
}>();

const emit = defineEmits<{
  click: [event: PointerEvent];
}>();
</script>

<template>
  <QBtn
    rounded
    unelevated
    :ripple="false"
    no-caps
    :color="
      disabled ? (variant === 'contained' ? 'gray-200' : 'gray-500') : color
    "
    :text-color="
      disabled ? (variant === 'contained' ? 'gray-400' : 'gray-300') : undefined
    "
    :disable="disabled"
    :loading="loading"
    :href="href"
    :target="target"
    :type="type"
    :outline="variant === 'outlined'"
    :flat="variant === 'text' || variant === 'flat'"
    :class="[
      'ws-button',
      {
        'ws-button--small': size === 'sm',
        'ws-button--medium': size === 'md',
        'ws-button--large': size === 'lg',

        'ws-button--primary': color === 'primary',
        'ws-button--promotion': color === 'promotion',
        'ws-button--danger': color === 'danger',
        'ws-button--gray-400': color === 'gray-400',
        'ws-button--gray-500': color === 'gray-500',
        'ws-button--white': color === 'white',

        'ws-button--contained': variant === 'contained',
        'ws-button--outlined': variant === 'outlined',
        'ws-button--flat': variant === 'flat',
        'ws-button--text': variant === 'text',

        'ws-button--disabled': disabled,

        'text-gray-500': !disabled && color === 'white',
        'text-gray-400': disabled,
      },
      $attrs.class,
    ]"
    :aid="aid"
    @click="emit('click', $event as PointerEvent)"
  >
    <QIcon
      v-if="icon && !iconEnd"
      :name="icon"
      size="0.875em"
      class="q-mr-sm"
    />

    <slot>{{ label }}</slot>

    <QIcon v-if="icon && iconEnd" :name="icon" size="0.875em" class="q-ml-sm" />
  </QBtn>
</template>

<style lang="scss" scoped>
.ws-button {
  line-height: normal;
  font-size: var(--ws-button-font-size);

  min-height: auto;
  padding: var(--ws-button-padding);

  :deep(.q-spinner) {
    font-size: var(--ws-button-spinner-font-size);
  }

  :is(&--outlined, &--text):not(&--disabled) {
    // Override Quasar's hover colors
    &:hover {
      color: var(--ws-button-hover-color) !important;
    }

    //prevent quasar default focus styling
    :deep(.q-focus-helper) {
      display: none;
    }
  }

  &--small {
    --ws-button-font-size: 0.8125rem;
    --ws-button-padding: 8px 20px;
    --ws-button-spinner-font-size: 0.8125rem;
  }

  &--medium {
    --ws-button-font-size: 0.875rem;
    --ws-button-padding: 10px 22px;
    --ws-button-spinner-font-size: 0.9375rem;
  }

  &--large {
    --ws-button-font-size: 1rem;
    --ws-button-padding: 12px 24px;
    --ws-button-spinner-font-size: 1.125rem;
  }

  &--text {
    --ws-button-padding: 0;
  }

  &--primary {
    --ws-button-hover-color: rgb(var(--color-primary-450));
  }

  &--promotion {
    --ws-button-hover-color: rgb(var(--color-promotion-450));
  }

  &--danger {
    --ws-button-hover-color: rgb(var(--color-danger-450));
  }

  &--gray-400 {
    --ws-button-hover-color: rgb(var(--color-gray-300));
  }

  &--gray-500,
  &--white {
    --ws-button-hover-color: rgb(var(--color-gray-400));
  }

  // Override Quasar's hover color
  &--gray-500#{&}--outlined:not(&--disabled):before {
    border-color: rgb(var(--color-gray-200)) !important;
  }
}
</style>
