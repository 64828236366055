<script setup lang="ts">
import { ref } from 'vue';

import WsCard from '../ws-card.vue';
import colorWheelPng from './color-wheel.png';
import WsColorPickerCustomColor from './ws-color-picker-custom-color.vue';

const selectedColor = defineModel<string>();

withDefaults(
  defineProps<{
    colorsInUse?: string[];
    suggestedColors?: string[];
  }>(),
  {
    colorsInUse: () => [],
    suggestedColors: () => [
      '#000000',
      '#545454',
      '#737373',
      '#A6A6A6',
      '#D9D9D9',
      '#55BC6C',
      '#82DEE4',
      '#60B4F9',
      '#5429E1',
      '#1D49A7',
      '#FADE70',
      '#F5BF6A',
      '#F1965C',
      '#EB473E',
      '#ED72C0',
    ],
  }
);

const addingColor = ref(false);
const addedColors = ref<string[]>([]);
</script>

<template>
  <WsColorPickerCustomColor
    v-if="addingColor"
    :initial-color="selectedColor"
    class="max-w-[240px]"
    @done="
      addedColors.push($event);
      selectedColor = $event;
      addingColor = false;
    "
    @cancel="addingColor = false"
  />

  <WsCard v-else class="max-w-max !p-3.5">
    <section>
      <h3 class="mb-3 text-base font-bold">Colors in use</h3>

      <div class="grid grid-cols-5 gap-2.5">
        <!-- Add color button -->
        <button
          class="flex h-8 w-8 items-center justify-center rounded"
          :style="{ backgroundImage: `url(${colorWheelPng})` }"
          aria-label="Add color"
          @click="addingColor = true"
        >
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <circle cx="9" cy="9" r="9" fill="white" />
            <path
              d="M4.73682 9H13.2631"
              stroke="black"
              stroke-width="1.3"
              stroke-linecap="round"
            />
            <path
              d="M9 4.73685L9 13.2632"
              stroke="black"
              stroke-width="1.3"
              stroke-linecap="round"
            />
          </svg>
        </button>

        <!-- Colors in use (+ added colors) -->
        <button
          v-for="color in [...colorsInUse, ...addedColors]"
          :key="color"
          class="h-8 w-8 rounded border border-black/10 aria-selected:ring-2 aria-selected:ring-primary aria-selected:ring-offset-2"
          :style="{ backgroundColor: color }"
          :aria-selected="selectedColor === color"
          @click="selectedColor = color"
        />
      </div>
    </section>

    <section class="mt-5">
      <h3 class="mb-3 text-base font-bold">Suggested colors</h3>

      <div class="grid grid-cols-5 gap-2.5">
        <button
          v-for="color in suggestedColors"
          :key="color"
          class="h-8 w-8 rounded border border-black/10 aria-selected:ring-2 aria-selected:ring-primary aria-selected:ring-offset-2"
          :style="{ backgroundColor: color }"
          :aria-selected="selectedColor === color"
          @click="selectedColor = color"
        />
      </div>
    </section>
  </WsCard>
</template>
