<script setup lang="ts">
import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/vue';
import { ListboxOptions } from '@headlessui/vue';
import { inject, useTemplateRef } from 'vue';

import { wsSelectInjectionKey } from './ws-select-injection-key';

defineOptions({
  inheritAttrs: false,
});

const context = inject(wsSelectInjectionKey);

if (!context) {
  throw new Error('WsSelectPanel must be used within a WsSelect component');
}

const { searchInput, aid, triggerElement } = context;

const panel = useTemplateRef<InstanceType<typeof ListboxOptions>>('panel');

const { floatingStyles } = useFloating(triggerElement, panel, {
  placement: 'bottom-start',
  middleware: [
    offset(6),
    flip({ padding: 6 }),
    size({
      apply({ rects, elements }) {
        Object.assign(elements.floating.style, {
          minWidth: `${rects.reference.width}px`,
        });
      },
    }),
  ],
  whileElementsMounted: autoUpdate,
});
</script>

<template>
  <Teleport to="body">
    <ListboxOptions
      ref="panel"
      as="div"
      class="absolute z-[10000] rounded bg-white text-base shadow focus:outline-none"
      :class="$attrs.class"
      :style="floatingStyles"
      :aid="`${aid}_PANEL`"
      @focus="searchInput?.focus()"
    >
      <slot />
    </ListboxOptions>
  </Teleport>
</template>
