import type { Directive } from 'vue';

type SpinnerSize = 's' | 'm' | 'l';

type TimeoutId = ReturnType<typeof setTimeout>;

const DELAY = 100;

const timersMap = new WeakMap<HTMLElement, TimeoutId>();

const applySpinnerColor = (el: HTMLElement, color: string) => {
  if (!CSS.supports('color', color)) {
    return console.warn(
      `LoadingDirective.applySpinnerColor: unsuppoerted color='${color}'`
    );
  }
  el.style.setProperty('--loader-color', color);
};

const isValidCssColor = (value?: string): boolean =>
  !!(value && CSS.supports('color', value));

export function createLoadingDirective(
  size: SpinnerSize
): Directive<HTMLElement, boolean, string> {
  return (el, binding, _vnode) => {
    const sizeClass = `state-loading-${size}`;

    const showLoading = binding.value;
    const color = isValidCssColor(binding.arg) ? binding.arg : undefined;

    if (showLoading) {
      if (timersMap.has(el)) return;

      const id = setTimeout(() => {
        el.classList.add(`state-loading`, sizeClass);
        if (color) {
          applySpinnerColor(el, color);
        }
        timersMap.delete(el);
      }, DELAY);

      timersMap.set(el, id);
    } else {
      if (timersMap.has(el)) {
        clearTimeout(timersMap.get(el));
        timersMap.delete(el);
      }

      el.classList.remove(`state-loading`, sizeClass);
    }
  };
}
