import { Dialog, Notify, Quasar } from 'quasar';
import { App } from 'vue';

import './style.scss';

export function useQuasarPlugin(app: App) {
  app.use(Quasar, {
    plugins: {
      Dialog,
      Notify,
    },
  });
}

export * from './button';
export * from './input';
export * from './menu';
export * from './select';
export * from './dialog';
export * from './prompt';
export * from './toast';
export * from './misc';

export * from './directives/loading';
