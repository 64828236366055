<script setup lang="ts">
import { QBadge } from 'quasar';
import { computed } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    label: string;
    size?: 'sm' | 'md';
    icon?: string;
    color?: 'primary' | 'gray';
    align?: 'top' | 'middle' | 'bottom';
    aid: string;
    iconPosition?: 'start' | 'end';
  }>(),
  {
    align: 'middle',
    size: 'md',
    color: 'primary',
    iconPosition: 'end',
  }
);

const mainColor = computed(() => {
  return props.color === 'primary' ? 'primary-300' : 'gray-100';
});

const textColor = computed(() => {
  return props.color === 'primary' ? 'primary' : 'gray-400';
});
</script>

<template>
  <QBadge
    :class="[
      'ws-badge',
      {
        'ws-badge--small': props.size === 'sm',
        'ws-badge--medium': props.size === 'md',
      },
      $attrs.class,
    ]"
    :style="$attrs.style"
    :align="props.align"
    :color="mainColor"
    :text-color="textColor"
    :aid="props.aid"
  >
    <span
      v-if="props.icon && props.iconPosition === 'start'"
      :class="['ws-badge__icon', props.icon]"
      aria-hidden="true"
    />
    {{ props.label }}
    <span
      v-if="props.icon && props.iconPosition === 'end'"
      :class="['ws-badge__icon', props.icon]"
      aria-hidden="true"
    />
  </QBadge>
</template>

<style lang="scss" scoped>
.ws-badge {
  // defaults as for medium size
  --ws-badge-font-size: 0.625rem;
  --ws-badge-padding: 4px 10px;
  --ws-badge-icon-size: 0.75rem;

  font-weight: 700;
  line-height: 0.875rem;
  gap: 4px;
  border-radius: 8px;
  font-size: var(--ws-badge-font-size);
  padding: var(--ws-badge-padding);

  &__icon {
    font-size: var(--ws-badge-icon-size);
  }

  &--small {
    --ws-badge-font-size: 0.5625rem;
    --ws-badge-padding: 2px 8px;
    --ws-badge-icon-size: 0.625rem;
  }
}
</style>
