<script setup lang="ts">
import { QSlider } from 'quasar';
import { computed } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const val = defineModel<number>();

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    readonly?: boolean;
    id?: string;
    type?: 'label' | 'edit' | undefined;
    aid: string;
    min?: number;
    max?: number;
    step?: number;
    suffix?: string;
    markers?: boolean;
    markerLabels?: Record<string, unknown>[];
    label: boolean;
  }>(),
  {
    type: undefined,
    min: 0,
    max: 100,
    step: 1,
    suffix: '',
    label: false,
    markerLabels: () => [],
  }
);

const emit = defineEmits<{
  change: [event: FocusEvent];
}>();

const validateStep = function (step: number, min: number, max: number): void {
  if (min && max && step && val?.value) {
    if (val.value < min) {
      val.value = min;
      return;
    }
    if (val.value > max) {
      val.value = max;
      return;
    }
    if (val.value % step !== 0) {
      val.value = Math.round(val.value / step) * step;
    }
  }
};

const markerLabelsOption = computed(() => {
  if (props.markerLabels.length > 0) {
    return { markerLabels: props.markerLabels };
  }
  return '';
});
</script>

<template>
  <div class="ws-slider">
    <div v-if="type === 'label'" class="row justify-between">
      <span class="ws-slider-small">A</span>
      <span class="ws-slider-big">A</span>
    </div>
    <div v-else-if="type === 'edit'" class="row justify-end">
      <input
        v-model.number="val"
        class="slider-input"
        @blur="validateStep(step, min, max)"
      />
      <span class="slider-input-label">{{ suffix }}</span>
    </div>

    <QSlider
      :id="id"
      v-model="val"
      :disable="disabled"
      :readonly="readonly"
      :type="type"
      :aid="aid"
      :min="min"
      :max="max"
      :step="step"
      :markers="markers"
      v-bind="markerLabelsOption"
      :label="label"
      color="primary"
      inner-track-color="primary-300"
      @change="emit('change', $event as FocusEvent)"
    >
    </QSlider>
  </div>
</template>

<style lang="scss" scoped>
.ws-slider {
  max-height: 40px;

  &-small {
    font-size: 8px;
  }

  &-big {
    font-size: 12px;
  }
}

.q-slider {
  &.disabled {
    opacity: 1 !important;
    :deep(.q-slider__thumb),
    :deep(.q-slider__selection) {
      color: rgb(var(--color-primary-300)) !important;
    }
    :deep(.q-slider__inner) {
      background-color: rgb(var(--color-primary-50)) !important;
    }
  }

  :deep(.q-slider__markers) {
    color: rgb(var(--color-primary));
  }

  &--active :deep(.q-slider__focus-ring) {
    background: currentColor;
    transform: scale3d(1.55, 1.55, 1) !important;
    opacity: 0.25;
  }

  &--active :deep(.q-slider__thumb-shape) {
    transform: scale(1) !important;
  }
}

.slider-input {
  height: 15px;
  width: 24px;
  font-size: 10px;
  text-align: right;
  border: 1px solid transparent;
  border-radius: 3px;
  color: rgb(var(--ws-gray-500));
  outline: none;
  display: inline;

  &:hover:not(:disabled),
  &:focus {
    border-color: rgb(var(--color-gray-200));
  }

  &-label {
    font-size: 10px;
    text-align: center;
  }
}
</style>
