<script setup lang="ts">
const hue = defineModel<number>({
  required: true,
  validator: (value: number) => value >= 0 && value <= 360,
});
</script>

<template>
  <input
    v-model.number="hue"
    type="range"
    min="0"
    max="360"
    class="block w-full cursor-pointer appearance-none bg-transparent focus-visible:outline-none"
    :style="{ '--ws-color-picker-hue-slider-value': hue }"
  />
</template>

<style scoped lang="scss">
input {
  --ws-color-picker-hue-slider-track-height: 0.5rem;
  --ws-color-picker-hue-slider-thumb-size: 1rem;

  @mixin ws-color-picker-hue-slider-track {
    height: var(--ws-color-picker-hue-slider-track-height);
    border-radius: var(--ws-color-picker-hue-slider-track-height);
    background: linear-gradient(90deg in hsl longer hue, red, red);
  }

  // Chrome and Safari
  &::-webkit-slider-runnable-track {
    @include ws-color-picker-hue-slider-track;
  }

  // Firefox
  &::-moz-range-track {
    @include ws-color-picker-hue-slider-track;
  }

  @mixin ws-color-picker-hue-slider-thumb {
    width: var(--ws-color-picker-hue-slider-thumb-size);
    height: var(--ws-color-picker-hue-slider-thumb-size);
    border-radius: 50%;
    border: 2px solid white;
    background: hsl(var(--ws-color-picker-hue-slider-value) 100% 50%);
    filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
  }

  // Chrome and Safari
  &::-webkit-slider-thumb {
    appearance: none;
    margin-top: calc(
      (var(--ws-color-picker-hue-slider-track-height) / 2) -
        (var(--ws-color-picker-hue-slider-thumb-size) / 2)
    );
    @include ws-color-picker-hue-slider-thumb;
  }

  // Firefox
  &::-moz-range-thumb {
    box-sizing: border-box;
    @include ws-color-picker-hue-slider-thumb;
  }

  @mixin ws-color-picker-hue-slider-thumb-focus {
    outline: 2px solid rgb(var(--color-primary));
  }

  &:focus-visible {
    // Chrome and Safari
    &::-webkit-slider-thumb {
      @include ws-color-picker-hue-slider-thumb-focus;
    }

    // Firefox
    &::-moz-range-thumb {
      @include ws-color-picker-hue-slider-thumb-focus;
    }
  }
}
</style>
